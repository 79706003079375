
import {defineComponent, ref, watch} from 'vue'
import ProcessService from "@/core/services/ProcessService";
import HighChart from "@/components/base/chart/HighChart.vue";

export default defineComponent({
  name: "ProcessOverview",
  components: {HighChart},
  props: {
    type: {},
  },
  setup(props) {
    const createdChart = ref({chart: {}, isLoading: true})
    const completedChart = ref({chart: {}, isLoading: true})
    const tiles = ref({active: 0, created: 0, completed: 0})
    const loadTiles = (type) => {
      ProcessService.overview(type).then(res => {
        tiles.value = res;
      })
      createdChart.value.isLoading = true;
      ProcessService.createdChart(type).then(res => {
        createdChart.value.chart = res;
      }).finally(() => {
        createdChart.value.isLoading = false;
      })
      completedChart.value.isLoading = true;
      ProcessService.completeChart(type).then(res => {
        completedChart.value.chart = res;
      }).finally(() => {
        completedChart.value.isLoading = false;
      })
    }
    loadTiles(props.type)

    watch(() => props.type, cb => {
      loadTiles(cb);
    })

    return {
      completedChart,
      createdChart,
      tiles
    }
  }
})
